import { createContext, useCallback, useEffect, useRef, useState } from "react";
import socketio from "socket.io-client";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const isDevelopment = process.env.NODE_ENV === "development";

export const SocketContext = createContext(null);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
  },
}));

export const SocketConnection = ({ children }) => {
  const classes = useStyles();
  const socket = useRef(null);
  const [isConnected, setConnectionStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const blockHandler = useCallback(({ status, message }) => {
    navigate(-1);
    enqueueSnackbar(message, { variant: status });
  }, []);

  useEffect(() => {
    if (!socket.current) {
      const connection = socketio(
        isDevelopment ? "http://localhost:1000/" : "/",
        {
          path: "/socket",
          transports: ["websocket"],
          rememberUpgrade: true,
          auth: {
            token: window.localStorage.getItem("accessToken"),
          },
        },
      );
      connection.on("connect", () => {
        socket.current = connection;
        connection.on("block", blockHandler);
        setConnectionStatus(true);
        enqueueSnackbar("Соединение с сервером установлено.", {
          variant: "success",
        });
      });

      connection.on("disconnect", () => {
        connection.off("block", blockHandler);
        socket.current = null;
        enqueueSnackbar("Соединение с сервером разорвано!", {
          variant: "warning",
        });
      });
      connection.on("connect_error", async (err) => {
        connection.disconnect();
        socket.current = null;
        await logout();
        enqueueSnackbar(err.message, { variant: "error" });
      });
    }
  }, []);

  return (
    <SocketContext.Provider value={{ socket: socket.current }}>
      {isConnected ? (
        children
      ) : (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </SocketContext.Provider>
  );
};

SocketConnection.propTypes = {
  children: PropTypes.node,
};
