import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useCallback, useState } from "react";
import { useDialog } from "src/hooks/useDialog";

export const ConfirmDialog = ({ actionMessage, onSubmit, children }) => {
  const { isOpen, close, open } = useDialog();
  const [confirming, setConfirming] = useState(false);
  const confirm = useCallback(async () => {
    try {
      setConfirming(true);
      await onSubmit();
    } catch (e) {
      console.error(e);
    } finally {
      setConfirming(false);
    }
  }, [onSubmit]);
  return (
    <>
      <Dialog open={isOpen} onClose={close} fullWidth maxWidth={"sm"}>
        <DialogContent>
          {actionMessage}
          <Typography>{` не обратимо! Продолжить операцию?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={confirming} variant={"outlined"} color={"error"} onClick={confirm}>
            Удалить
          </Button>
          <Button disabled={confirming} onClick={close}>Отмена</Button>
        </DialogActions>
      </Dialog>
      {children(open)}
    </>
  );
};
